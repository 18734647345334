import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul">{`Patreon (Coming Soon)`}</li>
      <li parentName="ul">{`The Centrist Project (Coming Soon)`}</li>
    </ul>
    <div style={{
      "marginBottom": "5rem"
    }}></div>
    <Title text="Links Only an Enlightened Grillist Would Use" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.pewresearch.org/topic/politics-policy/political-issues/issue-priorities/"
        }}>{`Pew Research Center - Issue Priorities`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://thehill.com/"
        }}>{`The Hill`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://apnews.com/"
        }}>{`Associated Press`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reuters.com/"
        }}>{`Reuters`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.farmiddle.com/"
        }}>{`The Far Middle`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://forwardparty.com/"
        }}>{`The Forward Party`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      