import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Logo } from '../../../components/Logo';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  The Enlightened Grillists Podcast
    </Text>
    <Logo fixedMode={false} mdxType="Logo" />
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Welcome!
    </Text>
    <p>{`Each week, Enlightened Grillists Chris (the Worm God), and A. (the resident Marxist AKA K. Marx (?)) bring a bit of good ol' common sense back to the political spectrum for you. `}</p>
    <p>{`Not as dumb as Cum Town but not as pedantic as Chapo Trap House.`}</p>
    <p>{`Come ascend with us and learn to think for yourself! 🧠`}</p>
    <p><strong parentName="p">{`Here's the deal: we record every Wednesday, and `}<em parentName="strong">{`try`}</em>{` to release the episode by the following Monday, to make your Mondays just a little bit brighter. ☀️`}</strong></p>
    <a href="/episodes">See all episodes here.</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      